const sliderPhotoGallery = async function( $block ) {
	const { Swiper } = await import( "swiper" );
	await import( "swiper/css/bundle" );
	const { Scrollbar } = await import( "swiper/modules" );
	const $slider = $block.find( ".gallery__slider" );
	if ( !$slider.length ) {return false;}
	
	let galleryImages = [];
	$( $slider ).each( function( index ) {
		let galleryImagesItem = $( this );
		galleryImagesItem.addClass( "projectImages-" + index );
		galleryImages[index] = new Swiper( ".projectImages-" + index, {
			modules: [Scrollbar],
			slidesPerView: 1,
			speed: 1000,
			spaceBetween: 16,
			scrollbar: {
				el: ".gallery__scrollbar-" + index,
				draggable: true,
			},
			breakpoints: {
				900: {slidesPerView: 3},
				600: {slidesPerView: 2},
			}
		} );
	} );

	// tabs
	$( ".construction__nav--item--title" ).click( function() {
		let $this = $( this );
		$this.next().slideToggle();
		$this.parent().toggleClass( "active" );
		$( ".construction__nav--item--title" ).not( this ).next().slideUp();
		$( ".construction__nav--item--title" ).not( this ).parent().removeClass( "active" );
	} );

	let activeTabTitle = $( ".construction__nav--item.active" ).find( ".construction__nav--item--title" ).text();
	$( ".construction__title span" ).text( activeTabTitle );
	$( ".construction__nav--item" ).on( "click", function() {
		$( ".construction__nav--item" ).removeClass( "active" );
		$( this ).addClass( "active" );
		var tabText = $( this ).find( ".construction__nav--item--title" ).text();
		$( ".construction__title span" ).text( tabText );
		var tabId = $( this ).data( "tab" );
		$( ".construction__gallery--tab" ).removeClass( "active" );
		$( "[data-tab='" + tabId + "']" ).addClass( "active" );
	} );
};
 
themeUtils.loadBlock( sliderPhotoGallery, "home-construction", ".construction" );